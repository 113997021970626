.forfamily {
  font-family: "geomanist-book", sans-serif;
}

.topsection {
  padding-top: 2rem;
  /* background-color: #767676; */
}
/* a{
    color: blue;
} */
.containerarticle {
  padding-top: 8rem;
  max-width: 880px;
  max-width: 1220px;
  padding: 0 40px;
  margin: 0 auto;
  width: 100%;
}
.articlecenter {
  margin-bottom: 3rem;
  text-align: center;
}
.pagetitleheader {
  font-size: 48px;
  font-weight: 400;
}
.pagesubtitleheader > h3 {
  margin: 1.39rem;
  font-family: "geomanist-regular", sans-serif;
  font-weight: 300;
  font-size: 22px;
  line-height: 1.36;
  color: #767676;
}

.topswitch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  position: relative;
}
.switchplan {
  position: relative;
  display: block;
  height: 32px;
}

/* .pricingcards{
    width: 300px;
    height: 584px;
} */
.pricingcards {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  padding: 3rem 2.5rem 2.5rem;
  position: relative;
  margin: auto;
}

.pricingtitle {
  font-size: 2.25rem;
  margin-left: 5px;
  line-height: 2.75rem;
  color: #333;
  font-family: "Geomanist-Book";
  margin-bottom: 1rem;
}
.pricingsubtitles {
  float: left;
}
.switcher > button {
  padding: 2px 5px;
  border-radius: 30px;
  background-color: #f1e3e3;
}
.switcher {
  /* width:350px; */
  /* height: 30px; */
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  /* background-color: #333; */
}

.costvalue {
  font-size: 3.375rem;
  /* line-height: 5rem; */
  color: #57bb71;
  margin-bottom: 0.25rem;
  /* padding-left: 1rem; */
  position: relative;
  float: left;
}
.costvaluechapni {
  width: 20px;
  font-size: 16px;
}
.costvaluedolor {
  position: absolute;
  font-size: 22px;
  left: -22%;
}
.carditems {
  width: 300px;
  padding: 40px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: auto;
}
.carditems > ul {
  position: relative;
}
.carditems > ul > li {
  /* position: absolute; */
  /* left:-5px; */
  float: left;
  padding: 5px;
  list-style: none;
}
.carditems > ul > li::before {
  content: "✓";
  color: #57bb71;
  margin-left: -5px;
}
.actionbtns {
  width: 100%;
  background-color: rgb(87, 187, 113);
  float: unset;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 4px;
  width: 182+32px;
  height: 48px;
  padding: 16px;
}
.actionbtns:hover {
  box-shadow: 0 0 30px 0 rgba(26, 191, 104, 0.4);
}
/* On mouse-over, add a deeper shadow */
.carditems:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.Ugrid {
  width: 1260px;
  height: 662px+130px;
  padding: 92px 0px 48px 0px;
  /* background-color: red; */
}
.anserDiv {
  width: 1140px;
  height: 662px;
  display: flex;
  justify-content: center;
  justify-content: space-between;
  /* background-color:yellow; */
  margin: auto;
}

.rowanswer {
  width: 45%;
}
.accordian {
  width: 50%;
}
.imgepic {
  width: 422px;
  margin-top: 100px;
  padding: 5px;
}
.pagetitle {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 30px;
  float: left;

  padding-left: 5px;
  /* font-size: 20px; */
}

.pagesubtitle > h3 {
  width: 400px;
  float: left;
  left: 0%;
  text-align: center;

  margin-bottom: 33px;
  font-size: 17px;
}
.pagesubtitle {
  padding: 20px 5px;
}
.pagesubtitle > img {
  margin-top: -20px;
}
/* ///////////////////////////////// */
.togglebtn {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.togglebtn1 {
  width: 80px;
  background-color: rgb(241, 230, 230);
  border-radius: 20px;
}
.togglebtn2 {
  width: 80px;
  background-color: rgb(241, 230, 230);
  border-radius: 20px;
}
