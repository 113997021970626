.rsContainer {
  height: 480px;
  margin: 8px;
  width: 98%;
  justify-content: space-between;
  padding: 100px 20px 20px 20px;
}
.subdiv {
  margin-left: 5%;
  width: "800px";
  height: "201px";
  padding: "30px";
  border: "5px solid red";
  float: left;
}

.page-title {
  margin-bottom: 0.5rem;
  font-size: 35px;
}
.page-title > h2 {
  font-size: 2.25rem;
  line-height: 1.22;
  font-size: 20px;
}
.page-subtitle {
  margin-bottom: 2rem;
}
.inp {
  width: 300px;
  height: 45px;
  padding: 20px;
  margin-right: 10px;
  border: 1px solid black;
  border-radius: 5px;
}
.page-subtitle > h3 {
  margin-left: 7px;
  font-size: 1.125rem;
  line-height: 1.44;
  color: #767676;
}

.signup_by_email > div {
  margin-left: 3px;
}
.signup_by_email > a {
  color: #767676;
  margin-top: -10px;
  margin-right: 20px;
  position: absolute;
  left: 110px;
}
.signup_by_email > span {
  /* z-index: 1; */
  bottom: -10px;
  position: relative;
}
.signup_by_email > a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.actionbtn {
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 3rem;
  border-radius: 4px;
  background: #57bb71;
  text-transform: none;
  font-size: 1rem;
  line-height: 1;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: normal;
  padding: 0 1rem;
  letter-spacing: 0;
  position: relative;
  min-width: 152px;
}
.dotlist {
  font-size: 1.125rem;
  line-height: 2;
  color: #333;
  list-style: disc;
}
/* .circledic{
    width: 5px;
    height: 5px;
    background-color: black;
    border-radius: 50%;
} */
.dotlist > li {
  float: left;
}
.picsize {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* margin-left: ; */
}
.socialpic > ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  float: right;
  /* background-color: red; */
  margin-top: -25px;
}
.socialpic > ul > li {
  margin: 5px;
  font-size: 20px;
}
